import React from 'react';
import data from '../../core/data/altura.json';
import { Card, CardGroup, Image } from 'react-bootstrap';
import bannercDrill from '../../img/drills/cordedDrills/banner_Drill.jpg'
import { Container } from './index';

export default function Altura() {
    return (
      <Container data={data}></Container>
    );
  }